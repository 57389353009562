import { gql } from '@apollo/client'

export const GET_ALL_ORDERS_IDS = gql`
    query getAllOrdersIds {
        receivedOrders: getOrders(status: received, active: true) { id }
        pendingOrders: getOrders(status: pending, active: true) { id }
        stoppedOrders: getOrders(status: stopped, active: true){ id }
        completedOrders: getOrders(status: completed, active: true){ id }
        processingOrders: getOrders(status: processing, active: true){ id }
    }
`

export const GET_ALL_ORDERS = gql`
    query getAllOrders {
        getOrders(addProducts:true){
            key: id
            id
            name
            confirmation_number
            ssos_status
            products {
                key: variant_id
                ssos_id
                ssos_status
                quantity
                shopify_id
                variant_id
                variant_title
                title
                engineer
                stopped_message
            }
            financial_status
            created_at
        }
    }
`

export const GET_ORDER = gql`
    query getOrder($id: String, $name: String) {
        getOrders(id: $id, name: $name){
            id
            name 
            products {
                ssos_id
                quantity
                shopify_id
                variant_id
            }
        }
    }
`

export const GET_ORDER_PRODUCTS = gql`
    query getOrders {
	    getOrders(id: $id) {
            id
            name
            products {
                ssos_id
                quantity
                shopify_id
                variant_id
            }
            created_at 
        }
    }
`

export const UPDATE_ORDER_PRODUCTS_ENGINEERS = gql`
    mutation UpdateOrderProductsEngineer($id: String, $input: [UpdateOrderProductsEngineersInput]) {
        updateOrderProductsEngineers(id: $id, input: $input) {
            key: id
            id
            name
            confirmation_number
            ssos_status
            engineers
            products {
                ssos_id
                ssos_status
                quantity
                shopify_id
                variant_id
                variant_title
                title
                engineer
            }
            financial_status
            created_at
        }
    }
`

export const SYNC_ORDERS = gql`
    mutation syncOrders {
        syncOrders {
            check
        }
    }
`

export const CREATE_ORDER_PRODUCTS = gql`
    mutation CreateOrderProducts ($id: String){
        createOrderProducts(id: $id){
            check
            message
            order {
                id
                products {
                    key: variant_id
                    ssos_id
                    title
                    shopify_id
                    ssos_status
                    engineer
                    quantity
                    variant_id
                    variant_title
                }
                engineers
            }
        }
    }
`

export const UPDATE_ORDER_PRODUCT_STATUS = gql`
    mutation UpdateOrderProductStatus($orderId: String!, $productId: String!, $status: String!, $stoppedMessage: String){
        updateOrderProductStatus(orderId: $orderId, productId: $productId, status: $status, stoppedMessage: $stoppedMessage){
            id
            name
            ssos_status
        }
    }
`

export const GET_ORDER_DETAILS = gql`
    query getOrder($id: String, $name: String) {
        getOrder(id: $id, name: $name){
            id,
            name
            confirmation_number
            ssos_status
            engineers 
            customer_email
            customer_name
            products {
                key: variant_id
                ssos_id
                title
                variant_title
                ssos_status
                shopify_id
                variant_id
                quantity
                engineer {
                    name
                }
                image_url
                variant_image_url
                stopped_message
            }
        }
    }
`

export const NO_AUTH_GET_ORDER_DETAILS = gql`
    query getOrder($id: String, $name: String) {
        noAuthGetOrder(id: $id, name: $name){
            id
            name
            confirmation_number
            ssos_status
            engineers 
            customer_email
            customer_name
            products {
                ssos_id
                title
                variant_title
                ssos_status
                shopify_id
                variant_id
                quantity
                engineer {
                    name
                }
                image_url
                variant_image_url
                stopped_message
            }
        }
    }
`