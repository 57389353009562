import React, { useMemo } from "react";

import { ApolloProvider } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { notification } from 'antd'
import { Provider } from "react-redux";
import store from './store'
import Router from './router'

const Context = React.createContext({ name: 'Default' });


//create a util to add the authorization headers / or find a way to change the client headers
export const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token')
  }
})

const App = () => {
  //@ts-ignore 
  const [_, contextHolder] = notification.useNotification();

  const contextValue = useMemo(() => ({ name: 'Pingalo' }), []);

  return (

    <ApolloProvider client={client}>
      <Provider store={store}>
        <Context.Provider value={contextValue}>
          {contextHolder}
          <Router />
        </Context.Provider>
      </Provider>
    </ApolloProvider>
  )
}

export default App;