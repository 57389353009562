import { FC, useState, ChangeEvent } from 'react'
import { Modal } from "antd";
import { Input, Divider } from 'antd';
import { UPDATE_ORDER_PRODUCT_STATUS } from '../../data/orders';
import { useMutation } from '@apollo/client';
type StopProductModalProps = {
    isModalOpen: boolean
    product: any
    onOk: () => void
    setters: any
    orderId: any
}

export const StopProductModal: FC<StopProductModalProps> = ({ product, isModalOpen, onOk, orderId, setters }) => {
    const [stoppedMessage, setStoppedMessage] = useState<string>(product?.stopped_message ?? "")
    const [okDisabled, setOkDisabled] = useState<boolean>(true)

    const [updateOrderProductStatus] = useMutation(UPDATE_ORDER_PRODUCT_STATUS)
    const handleCancel = () => {
        setters.setIsModalOpen(false)
        setters.setProduct(null)
    }

    const handleOk = async () => {
        await updateOrderProductStatus({
            variables: { productId: product!.ssos_id, orderId, status: "stopped", stoppedMessage: stoppedMessage }
        })

        onOk()
    }

    const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setOkDisabled(!e.target.value)
        setStoppedMessage(e.target.value)
    }

    return (
        <Modal title="Stop product" okButtonProps={{ disabled: okDisabled }} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            {
                product && <p>Product {product!.title} will be stopped.</p>
            }
            <Divider />
            <label style={{ fontWeight: '600' }}>Message to store:</label>
            <Input.TextArea style={{ height: '8rem', marginTop: '.3rem' }} value={stoppedMessage} placeholder='Message' onChange={handleMessageChange} />
        </Modal>
    )
}