import { Table } from 'antd'

interface VariantsTableProps {
    product: any
}

const VariantsTable = ({ product }: VariantsTableProps) => {
    const VariantImage = (variant: any) => {
        const src = variant.title !== 'Default Title' ? variant.image_url || "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg" : product.image_url
        return (
            <img width={75} src={src} alt={"Image " + product.name}></img>
        )
    }

    const VariantTitle = (title: string) => {
        return (
            <p>{title === 'Default Title' ? product.title : title}</p>
        )
    }
    const columns = [
        {
            title: "Image",
            key: "image_url",
            render: VariantImage,
            width: "100px"
        },
        {
            title: "Name",
            dataIndex: "title",
            key: "title",
            render: VariantTitle
        },
        {
            title: "SKU",
            dataIndex: "sku",
            key: "sku"
        },
    ]
    return (
        <Table columns={columns} dataSource={product.variants}>
        </Table>
    )
}

export default VariantsTable