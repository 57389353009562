import './index.css'

import GraphQLHandler from '../../util/request'

import { NO_AUTH_GET_ORDER_DETAILS } from '../../data/orders'
import { Table, Empty } from 'antd'
import { useParams } from 'react-router-dom'
import Loading from '../../components/Loading'
import { useEffect, useState } from 'react'
import { SSOSStatusTag } from '../../components/SSOSStatusTag';

export const ClientOrder = () => {

    const params = useParams()
    const graphql = new GraphQLHandler()

    const [data, setData] = useState()
    const [dataLoading, setDataLoading] = useState(true)

    const [order, setOrder]: any = useState()

    useEffect(() => {
        graphql.query({ query: NO_AUTH_GET_ORDER_DETAILS, variables: { name: params.name }, operationName: 'noAuthGetOrder' })
            .then((data: any) => {
                setData(data)
                setDataLoading(false)
            })
    }, [])
    useEffect(() => {
        if (data)
            setOrder(data)
    }, [data])


    if (dataLoading) return <Loading />

    const SSOSStatusTagRender = (_: any, { ssos_status }: any) => {
        return (
            <SSOSStatusTag ssos_status={ssos_status} />
        )
    }

    const TitleRender = (_: any, { title, variant_title }: any) => {
        return (
            <p>{title} {variant_title !== "Default Title" ? ` | ${variant_title}` : ''}</p>
        )
    }

    const ImageRender = (_: any, { image_url, variant_image_url }: any) => {
        return (
            <img width={100} src={variant_image_url ?? image_url} />
        )
    }
    const columns = [
        { title: "Image", width: "120px", key: "image", render: ImageRender },
        { title: "Title", key: "title", render: TitleRender },
        { title: "Status", width: "120px", dataIndex: "ssos_status", key: "ssos_status", render: SSOSStatusTagRender },
    ]

    return (
        <div className="body">
            <div className='client-order-container'>
                <div className="logo">
                    <img src="https://pingalostore.com/cdn/shop/files/icontransparent_90x@2x.png?v=1616028502" width={100} alt="pingalo logo" />
                </div>

                <center>
                    <h1>Order #{params.name}</h1>
                </center>

                {order && (
                    <>
                        <center>
                            <h2>Client info</h2>
                        </center>
                        <b>Customer name:</b>
                        <p style={{ marginTop: 5 }}>{order.customer_name}</p>
                        <b>Customer email: </b>
                        <p style={{ marginTop: 5 }}>{order.customer_email}</p>
                    </>
                )}

                {
                    order && order.products?.length > 0 ? <>
                        <center>
                            <h2>Products info</h2>
                            <Table dataSource={order?.products} columns={columns}></Table>
                        </center>
                    </> :
                        <div style={{ padding: '4rem 0' }}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={!order ? "No data for this order" : "This order hasn't been processed by the system"} />
                        </div>
                }

            </div>
        </div>
    )
}