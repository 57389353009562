import { Table, Tag, Dropdown, Button, Flex, Divider, Tooltip } from "antd";
import { ReloadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { IOrder } from '../../types/Order'
import { SSOSObject } from "../../types/General";
import SiteVariables from "../../theme/site.variables.json"
import type { MenuProps } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import { SSOSStatusTag } from "../../components/SSOSStatusTag";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_ORDERS, SYNC_ORDERS, CREATE_ORDER_PRODUCTS } from "../../data/orders";
import { GET_ENGINEERS_FOR_SELECT } from "../../data/engineers"
import { openNotification } from '../../util/notification'
import { useSelector } from 'react-redux';
import { SearchInput, handleSearchInputFilter } from "../../components/SearchInput";
import SendOrderModal from "./SendOrderModal";
const Orders = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: any) => state.user.data)
  const [syncOrdersMutation, { loading: ordersMutationLoading, error }] = useMutation(SYNC_ORDERS);

  const [createOrderProducts, { loading: createProductsLoading, error: errorCreateProducts }] = useMutation(CREATE_ORDER_PRODUCTS)

  const { data: ordersData, loading: ordersLoading, refetch: ordersQueryRefetch } = useQuery(GET_ALL_ORDERS);
  const { data: engineerData, loading: engineerLoading } = useQuery(GET_ENGINEERS_FOR_SELECT);

  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [sendOrder, setSendOrder]: [IOrder | undefined, any] = useState()
  const [orders, setOrders] = useState([])
  const [engineers, setEngineers] = useState([])

  useEffect(() => {
    if (ordersData) {
      setOrders(ordersData.getOrders)
    }
  }, [ordersData])

  useEffect(() => {
    if (engineerData)
      setEngineers(engineerData.getUsers)
  }, [engineerData])

  useEffect(() => {

    if (ordersLoading || createProductsLoading || ordersMutationLoading || !user) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [ordersLoading, createProductsLoading, ordersMutationLoading, user])

  const FinancialStatusTag = (_: any, { financial_status }: IOrder) => {
    const statusColor: SSOSObject = {
      "paid": SiteVariables["color-success-500"],
      "pending": SiteVariables["color-warning-600"]
    }
    return (
      <Flex justify="center" align="center">
        <Tag color={statusColor[financial_status]}>{financial_status.toUpperCase()}</Tag>
      </Flex>
    )
  }

  const SSOSStatusTagRender = (_: any, { name, ssos_status, products }: IOrder) => {
    let tooltipText = ""
    products.forEach(p => {
      if (p.ssos_status === "stopped") {
        tooltipText += `${p.title}: ${p.stopped_message}\n`
      }
    })

    return (
      <Flex justify="center" align="center">
        <SSOSStatusTag ssos_status={ssos_status} />
        {
          !!tooltipText &&
          <Tooltip title={tooltipText}>
            <div>
              <InfoCircleOutlined />
            </div>
          </Tooltip>
        }
      </Flex>
    )
  }

  const CreatedAtString = (_: any, { created_at }: IOrder) => {
    const date = new Date(created_at as any).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    return (
      <p>{date}</p>
    )
  }

  const ActionColumn = (_: any, record: IOrder) => {
    const actions: SSOSObject = {
      view: () => {
        navigate('/orders/' + record.id)
      },
      send: () => {
        if (record && !record.products || !record?.products.length) {
          const recordToSend = Object.assign({}, record)
          createOrderProducts({
            variables: {
              id: record.id
            }
          }).then((res) => {
            if (res.data.createOrderProducts.check) {
              recordToSend.products = res.data.createOrderProducts.order.products;
              setSendOrder(recordToSend)
              setIsSendModalOpen(true)
            } else {
              openNotification("info", "Order cannot be retrieved", res.data.createOrderProducts.message)
            }
          })
        }
        else {
          setSendOrder(record)
          setIsSendModalOpen(true)
        }
      },
    }
    const items: MenuProps["items"] = [
      { label: user.platform === 'SHOPPER' ? "Edit/View" : 'View', key: "view" },
    ]

    if (record.ssos_status === 'received' && user.platform === 'SHOPPER')
      items.push({ label: "Send order", key: "send" })
    const onClick = (e: any) => {
      actions[e.key]()
    }

    return (
      <Dropdown trigger={['click']} menu={{ items, onClick }} placement="bottom" arrow>
        <Button><MenuOutlined /></Button>
      </Dropdown>
    )
  }

  const handleSync = async () => {
    try {
      const { data } = await syncOrdersMutation();
      if (data.syncOrders.check) await refetchOrdersData()
    } catch (error) {
      /*** 
       * @TODO show notification of error
       */
    }
  };

  const refetchOrdersData = async () => {
    try {
      await ordersQueryRefetch();
    } catch (error) {
      console.error('Error refetching orders data:', error);
    }
  };

  const ordersColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Confirmation #", dataIndex: "confirmation_number", key: "confirmation_number" },
    { title: "Status", dataIndex: "ssos_status", key: "ssos_status", render: SSOSStatusTagRender },
    { title: "Financial status", dataIndex: "financial_status", key: "financial_status", render: FinancialStatusTag },
    { title: "Created at", dataIndex: "created_at", key: "created_at", render: CreatedAtString },
    { title: 'Action', key: 'action', render: ActionColumn }
  ]

  const sendSetters = {
    setIsModalOpen: setIsSendModalOpen,
    setSendOrder
  }

  const onOk = () => {
    ordersQueryRefetch()
    setIsSendModalOpen(false)
  }

  const handleOnFiltered = (search: string) => {
    handleSearchInputFilter(search, 'name', ordersData?.getOrders, setOrders)
  }

  if (loading) return <Loading />
  return (<>
    <Flex align="center" justify="space-between">
      <h1 style={{ marginBottom: '3px' }}>Orders</h1>
      {
        user?.platform === 'SHOPPER' && <Button onClick={handleSync} shape="circle" type="dashed" icon={<ReloadOutlined />} />
      }
    </Flex>

    <SearchInput attribute="name" onFiltered={handleOnFiltered} />

    <Table style={{ marginTop: '1rem' }} columns={ordersColumns} dataSource={orders} />
    <SendOrderModal onOk={onOk} setters={sendSetters} isModalOpen={isSendModalOpen} engineers={engineers} order={sendOrder}></SendOrderModal>
  </>)
}

export default Orders;
