import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { EditOutlined, HomeOutlined, InboxOutlined } from "@ant-design/icons";
import EditProductEngineerModal from './EditProductEngineerModal'

import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { GET_PRODUCTS, UPDATE_PRODUCT } from '../../data/products'

import VariantsTable from '../../components/VariantsTable';

import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';

const ViewProduct = () => {
    const { id } = useParams()
    const [engineerModalOpen, setEngineerModalOpen] = useState(false)
    const [updateProduct] = useMutation(UPDATE_PRODUCT)

    const { loading, data, error } = useQuery(GET_PRODUCTS, {
        variables: {
            ids: [id]
        }
    })
    const [product, setProduct]: any = useState(null)

    useEffect(() => {
        if (data)
            setProduct(data.getProducts[0])
    }, [data])

    const handleEngineerClick = () => {
        setEngineerModalOpen(true)
    }

    const handleEngineerModalCancel = () => {
        setEngineerModalOpen(false)
    }

    if (loading || (data && !product)) return <Loading />

    const onOk = async (engineer: string) => {
        const result = await updateProduct({
            variables: {
                id: product.id,
                input: {
                    engineer
                }
            }
        })
        setEngineerModalOpen(false)
        setProduct(result.data.updateProduct)
    }
    const getEngineer = (engineer: any): string => {
        if (engineer?.name) return `${engineer.name} ${engineer.lastname}`;
        else return "No engineer"
    }

    const breadcrumbPaths = [
        { path: '/products', title: 'Products' },
        { path: '/', title: `${product.title}` },
    ];

    return (
        <>
            <Breadcrumbs items={breadcrumbPaths} />
            <h1>{product.title}</h1>
            <img src={product.image_url}></img>
            <p><span className='bold'>Description: </span> {product.description || "N/A"} </p>
            <p><span className='bold'>Engineer: </span> {getEngineer(product.engineer)} <Button onClick={handleEngineerClick} size="small" type='link'><EditOutlined /></Button> </p>
            <p><span className='bold'>Shopify URL: </span> <a href={product.admin_url} target='blank'>{product.admin_url}</a>  </p>
            <VariantsTable product={product}></VariantsTable>
            <EditProductEngineerModal product={product} onOk={onOk} onCancel={handleEngineerModalCancel} isOpen={engineerModalOpen} />
        </>
    )
}

export default ViewProduct