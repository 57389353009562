import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import GraphQLHandler from '../../util/request'
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../store/slices/user';
import { GET_USER } from '../../data/users';
import Loading from '../Loading';
export const GraphQLContext: any = createContext(null);

export const GraphQLProvider = ({ children }: any) => {
    const navigate = useNavigate()
    const graphql = new GraphQLHandler(navigate)
    const dispatch = useDispatch()

    const [userLoading, setUserLoading] = useState(true)

    useEffect(() => {
        setUserLoading(true)
        graphql.query({ query: GET_USER, operationName: 'getUser' }).then((data: any) => {
            if (data) {
                dispatch(setUserInfo(data))
            }
            setUserLoading(false)
        })
    }, [])

    if (userLoading) return <Loading></Loading>
    return <GraphQLContext.Provider value={{ graphql }}>
        {children}
    </GraphQLContext.Provider>
}