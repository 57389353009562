import React, { useState } from "react"

import { Button, Modal, Form, Input } from "antd"
import type { FormInstance } from 'antd';
import debounce from 'lodash/debounce';


type ValidateStatus = "" | "validating" | "success" | "warning" | "error" | undefined;


const SubmitButton = ({ form, validateConfirmedPassword }: { form: FormInstance, validateConfirmedPassword: Function }) => {
    const [submittable, setSubmittable] = React.useState(false);

    const values = Form.useWatch([], form);
    const handleSubmit = () => {
        form.submit()
    }
    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittable(true)
                if (validateConfirmedPassword(values.confirm_password, values.password))
                    setSubmittable(false)
            },
            () => {
                setSubmittable(false)
            },
        );
    }, [values]);

    return (
        <Button onClick={handleSubmit} type="primary" htmlType="submit" disabled={!submittable}>
            Submit
        </Button>
    );
};

const EngineerModal = (props: { open: boolean, onOk: any, onCancel: any }) => {
    const validateConfirmedPassword = (confirmPassword: string, password: string) =>
        confirmPassword && password && confirmPassword !== password

    const [form] = Form.useForm();
    const [confirmStatus, setConfirmStatus] = useState('')
    const handleValuesChange = (_changedValues: any, allValues: any) => {
        if (validateConfirmedPassword(allValues.confirm_password, allValues.password)) {
            setConfirmStatus('error')
        }
        else setConfirmStatus('')
    }

    const validateMessages = {
        required: "'${label}' is required!",
    };

    return (
        <Modal footer={[
            <Button onClick={props.onCancel}>Cancel</Button>,
            <SubmitButton validateConfirmedPassword={validateConfirmedPassword} form={form}></SubmitButton>
        ]} title="Create engineer user" {...props}>
            <Form onFinish={props.onOk} validateMessages={validateMessages} onValuesChange={debounce(handleValuesChange, 300)} form={form} name="validateOnly" layout="vertical" autoComplete="off">
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="lastname" label="Lastname" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="username" label="E-Mail" rules={[{ required: true }]}>
                    <Input type='email' />
                </Form.Item>
                <Form.Item name="password" label="Password" rules={[{ required: true }]}>
                    <Input type='password' />
                </Form.Item>
                <Form.Item validateStatus={confirmStatus as ValidateStatus} name="confirm_password" label="Confirm password" rules={[{ required: true }]}>
                    <Input type='password' />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EngineerModal;