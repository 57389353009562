import { Modal, Select, Divider } from "antd";
import React, { useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { UPDATE_ORDER_PRODUCTS_ENGINEERS } from "../../data/orders";
import { IOrder } from "../../types/Order";
import { SSOSObject } from "../../types/General";

type SendOrderModalProps = {
  isModalOpen: any,
  engineers: any,
  setters: any,
  order: IOrder | undefined,
  onOk?: any
}

type ProductSelectProps = {
  product: any,
  engineers: Array<any>,
  productsEngineer: SSOSObject,
  onChange: React.EventHandler<any>
}
/*** 
* @TODO change engineer to current product engineer in SendOrderModal to use the same state
* */
const ProductSelect: React.FC<ProductSelectProps> = ({ product, productsEngineer, engineers, onChange }) => {
  const [engineer, setEngineer] = useState()

  useEffect(() => {
    setEngineer(productsEngineer[product.ssos_id])
  }, [productsEngineer])

  const handleSelectChange = (e: string) => {
    onChange({ engineer: e, productId: product.ssos_id })
  }

  const checkDisabledSelect = () => !["received", "pending"].includes(product.ssos_status)

  return (
    <div style={{ marginBottom: '15px' }}>
      <label>{product.title} // {product.variant_title}</label>
      {/* CUANDO SE CAMBIA UN PRODUCTO VARIANTE, CAMBIA EL INGENIERO DE TODAS LAS VARIANTES QUE PERTENECEN AL PRODUCTO DEL PRODUCTO VARIANTE CAMBIADO  */}
      <Select disabled={checkDisabledSelect()} onChange={handleSelectChange} value={engineer} style={{ width: '100%' }} options={engineers}></Select>
    </div>
  )
}

const SendOrderModal = ({ isModalOpen, engineers, setters, order, onOk }: SendOrderModalProps) => {
  const [
    updateOrderProductsEngineers,
    { loading: loadingUpdate, error: errorUpdate }
  ] = useMutation(UPDATE_ORDER_PRODUCTS_ENGINEERS)
  const [productsEngineer, setProductsEngineer]: [SSOSObject, any] = useState({})
  const [buttonEnabled, setButtonEnabled]: any = useState(false)
  const products = order?.products;
  const getInitialValues = () => {
    const map: SSOSObject = {}
    products?.forEach(p => {
      map[p.ssos_id] = p.engineer
    })
    setProductsEngineer(map)
  }

  useEffect(() => {
    getInitialValues();
  }, [order]);

  useEffect(() => {
    let enabled = true;
    for(const val of Object.values(productsEngineer))
      if(!val) {
        enabled = false 
        break
      }

    setButtonEnabled(enabled)
  }, [productsEngineer])

  const handleOk = async () => {
    const dataToSend = Object.keys(productsEngineer).map((productId) => ({
      productId,
      engineer: productsEngineer[productId]
    }))
    await updateOrderProductsEngineers({
      variables: { id: order?.id, input: dataToSend }
    })
    onOk()
  }

  const handleCancel = () => {
    setters.setIsModalOpen(false)
    setters.setSendOrder(null)
  }
  type HandleProductChangeArgs = {
    engineer: string;
    productId: string;
  }
  const handleProductChange = (e: HandleProductChangeArgs) => {
    setProductsEngineer({ ...productsEngineer, [e.productId]: e.engineer })
  }

  return (
    <Modal title={`Send Status ${order?.name}`} okButtonProps={{ disabled: !buttonEnabled }} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <p>This will start the manufacturing process.</p>
      <Divider />
      {
        order?.products.map((product: any) =>
          <ProductSelect
            productsEngineer={productsEngineer}
            key={product.ssos_id}
            engineers={engineers}
            product={product}
            onChange={handleProductChange}
          />
        )
      }
    </Modal>
  )
}

export default SendOrderModal