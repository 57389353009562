import React from "react";
import { Breadcrumb } from "antd";
import { IBreadcrumbs } from "./types";
import { Link } from "react-router-dom";

export const Breadcrumbs: React.FC<IBreadcrumbs> = (props) => {
  const { items } = props;

  const BreadcrumbItemRender = (currentRoute: any, params: any, items: any, paths: any) => {
    const isLast = currentRoute?.path === items[items.length - 1]?.path;

    return isLast
        ? <span>{currentRoute.title}</span>
        : <Link to={`/${paths.join("/")}`}>{currentRoute.title}</Link>;
}


  return (
    <Breadcrumb
      items={items}
      itemRender={BreadcrumbItemRender}
    />
  );
};
