import { Modal, Select, Button, Form } from 'antd'
import type { FormInstance } from 'antd';
import { useEffect, useState } from 'react'
import { GET_ENGINEERS_FOR_SELECT } from '../../data/engineers'
import { useQuery, } from '@apollo/client'
interface EditProductEngineerProps {
    isOpen: boolean,
    onCancel: any,
    product: any,
    onOk: any
}
const EditProductEngineerModal = ({ isOpen, product, onCancel, onOk, ...rest }: EditProductEngineerProps) => {
    const { data, loading, error } = useQuery(GET_ENGINEERS_FOR_SELECT)
    const [engineer, setEngineer] = useState(product.engineer?.id)
    const [engineers, setEngineers] = useState();
    useEffect(() => {
        if (data)
            setEngineers(data.getUsers)

    }, [data])

    const SubmitButton = ({ form }: { form: FormInstance }) => {
        const [submittable, setSubmittable] = useState(false);

        const values = Form.useWatch([], form);
        const handleSubmit = () => {
            form.submit()
        }
        useEffect(() => {
            form.validateFields({ validateOnly: true }).then(
                () => {
                    if (engineer !== product.engineer?.id)
                        setSubmittable(true)
                    else setSubmittable(false)
                },
                () => {
                    setSubmittable(false)
                },
            );
        }, [values]);

        return (
            <Button onClick={handleSubmit} type="primary" htmlType="submit" disabled={!submittable}>
                Submit
            </Button>
        );
    };

    const handleEngineerChange = (e: any) => {
        setEngineer(e)
    }

    const validateMessages = {
        required: "'${label}' is required!",
    };


    const [form] = Form.useForm();

    return (
        <>
            <Modal footer={[
                <Button key="cancel" onClick={onCancel}>Cancel</Button>,
                <SubmitButton key="submit" form={form}></SubmitButton>
            ]} title="Update engineer" open={isOpen} {...rest} onCancel={onCancel}>
                <Form onFinish={() => onOk(engineer)} validateMessages={validateMessages} form={form} name="updateEngineer" layout="vertical" autoComplete="off">
                    <Form.Item name="engineer" initialValue={engineer} label="Engineer" rules={[{ required: true }]}>
                        <Select onChange={handleEngineerChange} value={engineer} options={engineers} disabled={loading || !engineers}></Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default EditProductEngineerModal