import { gql } from "@apollo/client"

const PRODUCT_ATTRIBUTES_QUERY = `
key: id
id
shopify_id
title
description
image_url
admin_url
variants {
    key: shopify_id
    title
    sku
    image_url
    shopify_id
},
engineer{
    id,
    name,
    lastname
}
`

export const GET_PRODUCTS = gql`
    query ProductsQuery ($ids: [String]){
        getProducts(ids: $ids) {
            ${PRODUCT_ATTRIBUTES_QUERY}
        }
    }
`

export const GET_SHOPIFY_PRODUCTS = gql`
    query ShopifyProductsQuery {
        getShopifyProducts {
            check
            message
            products {
                key: shopify_id
                title
                shopify_id
                description
                image_url
                variants {
                    shopify_id
                    title,
                    sku,
                    image_url
                },
            }
        }
    }
`

export const CREATE_PRODUCTS = gql`
    mutation createProducts($input: [ProductInput]) {
        createProducts(input: $input) {
            title
            shopify_id
            description
            image_url
            variants {
                shopify_id
                title,
                sku,
                image_url
		    },
        }
    }
`

export const UPDATE_PRODUCT = gql`
    mutation updateProduct($input: ProductInput, $id: String!){
        updateProduct(input: $input, id: $id){
            ${PRODUCT_ATTRIBUTES_QUERY}
        }
    }
`