import { Tag } from "antd";

import SiteVariables from "../../theme/site.variables.json"
import { SSOSObject } from "../../types/General";

export const SSOSStatusTag = ({ ssos_status }: any) => {
    const statusColor: SSOSObject = {
        "received": SiteVariables["color-primary-500"],
        "pending": SiteVariables["color-primary-900"],
        "processing": SiteVariables["color-info-500"],
        "completed": SiteVariables["color-success-500"],
        "stopped": SiteVariables["color-danger-500"]
    }
    return (
        <Tag color={statusColor[ssos_status]}>{ssos_status.toUpperCase()}</Tag>
    )
}