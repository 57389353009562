import './index.css'
import { Button, Flex, Table, } from "antd"

import { useEffect, useState, useContext } from "react"
import Loading from "../../components/Loading"
import { GET_ENGINEERS, CREATE_ENGINEER } from "../../data/engineers"
import { PlusOutlined } from "@ant-design/icons"
import EngineerModal from "./EngineerModal"
import { GraphQLContext } from '../../components/GraphqlProvider/GraphqlProvider'


const Engineers = () => {
    const [data, setData] = useState()
    const [dataLoading, setDataLoading] = useState(true)
    const { graphql } = useContext(GraphQLContext) as any;
    const [engineers, setEngineers]: [any, any] = useState([]);
    const [engineerModal, setEngineerModal] = useState(false)

    useEffect(() => {
        fetchEngineers()
    }, [])

    useEffect(() => {
        if (data)
            setEngineers(data)
    }, [data])

    const NameColumn = (record: any) => {
        return (<p>
            {record?.name} {record?.lastname}
        </p>)
    }

    const handleAddClick = () => {
        setEngineerModal(true)
    }

    const fetchEngineers = (fetchPolicy?: string) => {
        setDataLoading(true)

        const queryOptions: any = { query: GET_ENGINEERS, operationName: 'getUsers' }

        if (fetchPolicy) queryOptions.fetchPolicy = fetchPolicy

        graphql.query(queryOptions).then((data: any) => {
            setData(data)
            setDataLoading(false)
        })
    }

    const handleOkClick = async (e: any) => {
        delete e.confirm_password;
        e.platform = "ENGINEER";
        e.type = "ADMIN"
        graphql.mutate({
            mutation: CREATE_ENGINEER,
            variables: {
                input: e
            },
            operationName: 'signUp'
        }).then(() => {
            fetchEngineers('no-cache')
            setEngineerModal(false)
        })
    }

    const handleCancelClick = () => {
        setEngineerModal(false)
    }

    if (dataLoading) return <Loading />

    const columns = [
        { title: "Name", key: "lastname", render: NameColumn },
        { title: "Username", dataIndex: "username", key: "username" },
        { title: 'Action', key: 'action' }
    ]
    return (
        <>
            <Flex align="center" justify={"space-between"}>
                <h1>Engineers</h1>
                <Button onClick={handleAddClick} shape="circle" type="dashed" icon={<PlusOutlined />} />
            </Flex>
            <Table columns={columns} dataSource={engineers}></Table>

            <EngineerModal onOk={handleOkClick} onCancel={handleCancelClick} open={engineerModal} ></EngineerModal>
        </>
    )
}

export default Engineers