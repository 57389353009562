import React, { useState, useEffect } from 'react';
import {
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  UnorderedListOutlined,
  LogoutOutlined,
  InboxOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, Flex, theme } from 'antd';
import Logo from '../../components/Logo'
import Loading from '../../components/Loading'

import { GraphQLProvider } from '../../components/GraphqlProvider/GraphqlProvider';
import { openNotification } from '../../util/notification'

import { useNavigate, Outlet, useLocation } from "react-router-dom";

import './index.css'
import { useSelector } from 'react-redux';

const { Header, Sider, Content } = Layout;

type MenuItem = {
  key: string
  icon: React.ReactNode
  label: string
}

const App: React.FC = () => {
  const user = useSelector((state: any) => state.user.data)

  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token')
  const [menuItems, setMenuItems] = useState<MenuItem[]>()
  const throwUserError = () => {
    openNotification('error', 'Auth error', 'Not logged in. Please authenticate yourself.')
    navigate('/login')
    if (token) localStorage.removeItem('token')
  }

  if (!token) throwUserError()

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {
    if (token && user) {
      let userMenuItems = [
        {
          key: '/home',
          icon: <HomeOutlined />,
          label: 'Home',
        },
        {
          key: '/orders',
          icon: <UnorderedListOutlined />,
          label: 'Orders',
        },
        {
          key: '/products',
          icon: <InboxOutlined />,
          label: 'Products',
        },
      ]
      if (user.platform === 'SHOPPER') {
        userMenuItems = [...userMenuItems, {
          key: '/engineers',
          icon: <UserOutlined />,
          label: 'Engineers',
        },]
      }
      setMenuItems(userMenuItems);
      if (window.location.pathname === '/'){ navigate('/home')}
    }
  }, [user, token])

  const getDefaultSelectedKey = () => ["/" + location.pathname.split('/')[1]]

  const handleMenuChange = (e: any) => {
    navigate(e.key)
  }

  const handleLogout = () => {
    localStorage.removeItem('token')
    navigate('/login')
  }

  return (
    <GraphQLProvider>
      <Layout style={{ height: '100vh' }}>
        <Sider className='bg-primary' trigger={null} collapsible collapsed={collapsed}>
          <Flex style={{ height: '100%', paddingTop: 10, paddingBottom: 10 }} justify='space-between' align='center' vertical>
            <div style={{ width: '100%' }}>
              <Logo color='light' size={collapsed ? 'small' : ''} />
              <Menu
                onClick={handleMenuChange}
                className='bg-primary nav-menu'
                mode="inline"
                defaultSelectedKeys={getDefaultSelectedKey()}
                items={menuItems}
              />
            </div>
            <div>
              <Button
                icon={<LogoutOutlined />}
                className='no-border'
                ghost
                style={{ width: '100%' }}
                onClick={handleLogout}>
                {collapsed ? '' : 'Logout'}
              </Button>
            </div>
          </Flex>
        </Sider>
        <Layout>
          <Header style={{ padding: 0, background: colorBgContainer }}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 50,
                height: 50,
                marginLeft: 10
              }}
            />
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              maxHeight: '100%',
              overflow: 'auto'
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </GraphQLProvider>
  );
};

export default App;