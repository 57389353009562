import { notification } from 'antd';

type NotificationType = 'success' | 'info' | 'warning' | 'error';


export const openNotification = (type: NotificationType, message: string, description: string) => {
    const notificationMethod = notification[type as 'success' | 'info' | 'warning' | 'error'];

    if (notificationMethod) {
        notificationMethod({
            message,
            description,
            placement: 'bottomRight',
        });
    }
};