import { Input } from 'antd'
import { debounce } from 'lodash'
import { ChangeEvent, useState, useEffect, FC } from 'react'

type SearchInputProps = {
    onFiltered: (search: string) => void
    attribute: string
}

export const SearchInput: FC<SearchInputProps> = ({
    onFiltered,
    attribute
}) => {
    const [search, setSearch]: any = useState()

    const handleInputChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }, 300)

    useEffect(() => {
        onFiltered(search)
    }, [search])

    return (
        <>
            <p style={{ fontWeight: '600', marginBottom: '4px' }}>Search by {attribute}:</p>
            <Input style={{ width: '348px' }} onChange={handleInputChange} />
        </>
    )
}

export const handleSearchInputFilter = (search: string, attribute: string, originalData: any, setter: any) => {
    if (originalData)
        if (search) {
            const filteredData = originalData.filter((p: any) => p[attribute].toLowerCase().includes(search.toLowerCase()))
            setter(filteredData)
        } else {
            setter(originalData)
        }
}