import { Flex } from 'antd';

import { useContext, useEffect, useState } from 'react';
import { GET_ALL_ORDERS_IDS } from '../../data/orders';
import { useSelector } from 'react-redux';
import { GraphQLContext } from '../../components/GraphqlProvider/GraphqlProvider'
import Loading from '../../components/Loading';
import './index.css'

const HomeBadge = ({ color, number, label }: { color: string, number: number, to?: string, label: string }) => {
    return (
        <Flex style={{ padding: 15, margin: 10 }} align={'center'} vertical justify={'center'} className={`${color} home-badge`}>
            <span style={{ fontSize: 40 }}>{number}</span>
            <span>
                {label}
            </span>
        </Flex>
    );
}

const Home = () => {
    const [ordersLoading, setOrdersLoading] = useState(true)
    const user = useSelector((state: any) => state.user.data)
    const [data, setData] = useState<any>()
    const { graphql } = useContext(GraphQLContext) as any
    const [receivedOrdersCount, setReceivedOrders] = useState(data?.receivedOrders.length || 0)
    const [pendingOrdersCount, setPendingOrders] = useState(data?.pendingOrders.length || 0)
    const [completedOrdersCount, setCompletedOrders] = useState(data?.completedOrders.length || 0)
    const [stoppedOrdersCount, setStoppedOrders] = useState(data?.stoppedOrders.length || 0)
    const [processingOrdersCount, setProcessingOrders] = useState(data?.processingOrders.length || 0)

    useEffect(() => {
        setOrdersLoading(true)
        graphql.query({ query: GET_ALL_ORDERS_IDS }).then((data: any) => {
            setData(data)
            setOrdersLoading(false)
        })
    }, [])

    useEffect(() => {
        setReceivedOrders(data?.receivedOrders.length)
        setPendingOrders(data?.pendingOrders.length)
        setCompletedOrders(data?.completedOrders.length)
        setStoppedOrders(data?.stoppedOrders.length)
        setProcessingOrders(data?.processingOrders.length)
    }, [data])
    if (ordersLoading) return <Loading />
    // if (error) return <p>Error: {error.message}</p>;

    return (
        <>
            {<h1>Welcome, {user?.name}!</h1>}
            <Flex style={{ height: 500 }} align={"center"} vertical justify={"center"}>
                <Flex>
                    <HomeBadge label='received orders' number={receivedOrdersCount} color="primary"></HomeBadge>
                    <HomeBadge label='completed orders' number={completedOrdersCount} color="success"></HomeBadge>
                </Flex>
                <Flex>
                    {user?.platform === 'ENGINEER' ?
                        <HomeBadge label='pending orders' number={pendingOrdersCount} color="primary-900"></HomeBadge> :
                        <HomeBadge label='stopped orders' number={stoppedOrdersCount} color="error"></HomeBadge>
                    }

                    <HomeBadge label='processing orders' number={processingOrdersCount} color="info"></HomeBadge>
                </Flex>
            </Flex>
        </>
    )
}

export default Home;