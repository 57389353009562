import './index.css'

import { useEffect } from 'react';
import Logo from "../../components/Logo";
import { Flex, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { useState, ChangeEvent } from 'react';

import { Input, Button } from "antd";
import { LoginOutlined } from '@ant-design/icons'
import { LOGIN } from '../../data/users';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../store/slices/user';


const Login = () => {
    const navigate = useNavigate()
    const token = localStorage.getItem('token')
    const dispatch = useDispatch()
    const [login] = useMutation(LOGIN)
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();

    const handleUserChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value)
    }

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }
    const handleLogin = async () => {
        localStorage.removeItem('token')
        try {
            const result = await login({
                variables: {
                    input: {
                        "username": username,
                        "password": password
                    },
                },
            });
            localStorage.setItem('token', result.data.noAuthLogin.token);
            const userResult = result.data.noAuthLogin.user;
            dispatch(setUserInfo(userResult))
            navigate('/home')

        } catch (error) {
            console.error('Error updating username:', error);
        }
    }
    useEffect(() => {
        if (token) navigate('/')
    }, [token])

    return (
        <>
            <div style={{
                height: '100vh'
            }} className="bg-primary">
                <Flex style={{ height: '100%' }} align='center' justify="center">
                    <Card className="login-card">
                        <Logo></Logo>
                        <Input placeholder='Username' value={username} onChange={handleUserChange} />
                        <Input.Password placeholder='Password' value={password} onChange={handlePasswordChange} style={{ marginTop: 10, marginBottom: 10 }} />
                        <center>
                            <Button onClick={handleLogin} icon={<LoginOutlined />} type='primary' color='primary'>Log in</Button>
                            {/* 
                    @TODO: Por escabilidad, se pueden crear usuarios y linkear ingenieros con el vendedor a través de un codigo único de ingenieros
                */}
                            {/* <Link to="/auth/sign-up" style={{ display: "block", margin: 0 }} type="link">Not registered? Click here!</Link> */}
                        </center>
                    </Card>
                </Flex>
            </div>
        </>
    )
}

export default Login;