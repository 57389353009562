import { Table, Flex, Button, Dropdown } from 'antd'

import { PlusOutlined } from '@ant-design/icons';
import { GET_PRODUCTS } from '../../data/products'
import { useEffect, useState, useContext } from 'react';
import ProductsModal from './ProductsModal';
import { SSOSObject } from '../../types/General';
import { MenuOutlined } from '@ant-design/icons';
import Loading from '../../components/Loading';
import type { MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SearchInput, handleSearchInputFilter } from '../../components/SearchInput';
import { GraphQLContext } from '../../components/GraphqlProvider/GraphqlProvider'

const ProductImage = (imageUrl: string) => {
    return (
        <>
            <img src={imageUrl} width={50} alt="" />
        </>
    )
}
const ProviderColumn = (engineer: any) => {
    return (
        <>
            <p>{engineer?.name} {engineer?.lastname}</p>
        </>
    )
}

const Products = () => {
    const navigate = useNavigate()
    const { graphql } = useContext(GraphQLContext) as any;
    const [loading, setLoading] = useState(true)
    const [productsData, setProductsData] = useState()
    const [products, setProducts] = useState([])
    const [isOpen, setIsOpen]: any = useState(false)
    const user = useSelector((state: any) => state.user.data)

    useEffect(() => {
        fetchProducts()
    }, [])

    useEffect(() => {
        if (productsData) setProducts(productsData)
    }, [productsData])

    const fetchProducts = () => {
        setLoading(true)
        graphql.query({
            query: GET_PRODUCTS,
            operationName: 'getProducts',
            fetchPolicy: 'no-cache'
        }).then((data: any) => {
            console.log('data', data)
            setProductsData(data)
            setLoading(false)
        })
    }

    const ActionColumn = (_: any, record: any) => {
        const actions: SSOSObject = {
            view: () => {
                navigate('/products/' + record.id)
            },
        }
        const items: MenuProps["items"] = [
            { label: "Edit/View", key: "view" },
        ]

        const onClick = (e: any) => {
            actions[e.key]()
        }

        return (
            <Dropdown trigger={['click']} menu={{ items, onClick }} placement="bottom" arrow>
                <Button><MenuOutlined /></Button>
            </Dropdown>
        )
    }

    const productColumns = [
        { title: "", key: "image_url", dataIndex: "image_url", render: ProductImage },
        { title: "Title", dataIndex: "title", key: "title" },
        { title: "Description", dataIndex: "descripton", key: "description" },
        { title: "Provider", key: "engineer", dataIndex: "engineer", render: ProviderColumn },
        { title: 'Action', key: 'action', render: ActionColumn }
    ]

    const handleAddClick = () => {
        setIsOpen(true)
    }

    const actions = { setIsOpen, refetchProducts: fetchProducts }
    const handleOnFiltered = (search: string) => {
        handleSearchInputFilter(search, 'title', productsData, setProducts)
    }

    if (loading) return <Loading />
    return (
        <>
            <Flex align="center" justify={"space-between"}>
                <h1>Products</h1>
                {user?.platform === 'SHOPPER' &&
                    <Button onClick={handleAddClick} shape="circle" type="dashed" icon={<PlusOutlined />} />}

            </Flex>
            <SearchInput attribute='title' onFiltered={handleOnFiltered} />
            <Table style={{ marginTop: '1rem' }} columns={productColumns} dataSource={products} />
            <ProductsModal title="Add products from Shopify" actions={actions} isOpen={isOpen}></ProductsModal>
        </>
    )
}

export default Products;