import { Modal } from "antd";
import React, { useEffect } from 'react'
import { IProduct } from "../../types/Order";
import { SSOSObject } from "../../types/General";
import { useMutation } from '@apollo/client';
import { UPDATE_ORDER_PRODUCT_STATUS } from '../../data/orders';
import Loading from '../../components/Loading'
import { openNotification } from '../../util/notification'

type ChangeStatusModalProps = {
  isModalOpen: boolean
  onOk: () => void
  setters: any
  product: IProduct | undefined
  stop?: boolean
  orderId: string

}

export const ChangeStatusModal: React.FC<ChangeStatusModalProps> = ({
  isModalOpen,
  onOk,
  product,
  orderId,
  setters,
  stop = false
}) => {
  const [updateOrderProductStatus, { loading: updateOrderLoading, error: updateOrderError }] = useMutation(UPDATE_ORDER_PRODUCT_STATUS)

  useEffect(() => {
    if (updateOrderError)
      openNotification("error", "Error!", "Error updating status. Please contact support.")
  }, [updateOrderError])
  const nextStatus: SSOSObject = {
    "received": "pending",
    "pending": "processing",
    "processing": "completed",
    "stopped": "processing"
  }
  const handleCancel = () => {
    setters.setIsModalOpen(false)
    setters.setProduct(null)
  }

  const handleOk = async () => {
    await updateOrderProductStatus({
      variables: { productId: product!.ssos_id, orderId, status: nextStatus[product!.ssos_status] }
    })

    onOk()
  }

  if (updateOrderLoading) return <Loading />

  return (
    <Modal title="Change status" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      {
        product && <p>Product {product!.title} will be sent to {nextStatus[product!.ssos_status.toLowerCase()].toUpperCase()}</p>
      }
    </Modal>
  )
}