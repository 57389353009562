import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        setUserInfo(state, action) {
            state.data = action.payload
        },
        getUserStart(state) {
            state.loading = true;
            state.error = null;
        },
        getUserSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        getUserFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
    }
})
export const selectUserData = (state: any) => state.user.data;
export const selectUserLoading = (state: any) => state.user.loading;
export const selectUserError = (state: any) => state.user.error;

export const { getUserStart, getUserSuccess, getUserFailure, setUserInfo } = userSlice.actions;
