import { gql } from '@apollo/client'

export const LOGIN = gql`
    mutation login($input: LoginInput) {
        noAuthLogin(input: $input) {
            token
            user {
                id
                username
                name
                lastname
                platform
                type
            }
        }
    }
`

export const GET_USER = gql`
    query getUser {
        getUser {
            id
            username
            name
            lastname
            platform
            type
        }
    }
`