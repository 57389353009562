import { gql } from '@apollo/client'

export const GET_ENGINEERS = gql`
    query getEngineers {
        getUsers(platform: ENGINEER) {
            key: id
            id
            username
            name
            lastname
            platform
            type
        }
    }
`

export const CREATE_ENGINEER = gql`
    mutation createEngineer ($input: CreateUserInput) {
        signUp(input: $input){
            id
            username
            name
            lastname
        }
    }
`

export const GET_ENGINEERS_FOR_SELECT = gql`
    query getEngineers {
        getUsers(platform: ENGINEER) {
            value: id
            label: full_name
        }
    }
`