import { Flex, Spin } from 'antd'

const Loading = () => (<>
  <Flex
    style={{
      height: "100%",
      position: 'absolute',
      width: '100%',
      background: '#f2f2f2',
      top: '0',
      left: '0'
    }}
    justify={'center'}
    align={'center'}
  >
    <Spin size='large'></Spin>
  </Flex>
</>)

export default Loading