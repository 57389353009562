import { GET_ORDER_DETAILS } from '../../data/orders'
import { useQuery } from '@apollo/client';
import { useState } from 'react'
import Loading from '../../components/Loading'
import { useParams } from 'react-router-dom'
import { SSOSStatusTag } from '../../components/SSOSStatusTag';
import { MenuOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Table, Dropdown, Button, Flex, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import { ChangeStatusModal } from "./ChangeStatusModal";
import { SSOSObject } from "../../types/General";
import { useSelector } from 'react-redux';
import Breadcrumbs from '../../components/Breadcrumbs';
import { StopProductModal } from './StopProductModal';

const Details = () => {
    const { id } = useParams()
    const { data, loading, error, refetch: orderRefetch } = useQuery(GET_ORDER_DETAILS, { variables: { id } })
    const [sendProductModal, setSendProductModal] = useState(false)
    const [stopProductModal, setStopProductModal] = useState(false)
    const [product, setProduct]: any = useState()
    const user = useSelector((state: any) => state.user.data)

    if (loading || !data) return <Loading />
    const order = data.getOrder

    const SSOSStatusTagRender = (_: any, { ssos_status, stopped_message }: any) => {
        console.log(stopped_message)
        return (
            <Flex justify='center' align='center'>
                <SSOSStatusTag ssos_status={ssos_status} />
                {stopped_message && <Tooltip title={stopped_message}>
                    <div>
                        <InfoCircleOutlined />
                    </div>
                </Tooltip>}
            </Flex>
        )
    }

    const TitleRender = (_: any, { title, variant_title }: any) => {
        return (
            <p>{title} {variant_title !== "Default Title" ? ` | ${variant_title}` : ''}</p>
        )
    }

    const ImageRender = (_: any, { image_url, variant_image_url }: any) => {
        return (
            <img width={100} src={variant_image_url ?? image_url} />
        )
    }

    const EngineerRender = (_: any, { engineer }: any) => {
        if (engineer)
            return (
                <p>{engineer.name}</p>
            )
        return <p>No engineer</p>
    }

    const ActionColumn = (_: any, record: any) => {
        const actions: SSOSObject = {
            status: () => {
                setProduct(Object.assign({}, record))
                setSendProductModal(true)
            },
            stop: () => {
                setProduct(Object.assign({}, record))
                setStopProductModal(true)
            }
        }
        let items: MenuProps["items"] = []
        if (user.platform === 'ENGINEER')
            items = [
                ...items,
                {
                    label: "Change status",
                    key: "status",
                    disabled: ["completed", "cancelled"].includes(record.ssos_status)
                },
                {
                    label: "Stop order",
                    key: "stop",
                    disabled: ["completed", "cancelled"].includes(record.ssos_status)
                }
            ]
        if (user.platform === 'SHOPPER')
            items = [...items, { label: "Change engineer", key: "engineer", disabled: true/* ["completed", "cancelled", 'processing'].includes(record.ssos_status) */ }]

        const onClick = (e: any) => {
            actions[e.key]()
        }

        return (
            <Dropdown trigger={['click']} menu={{ items, onClick }} placement="bottom" arrow>
                <Button><MenuOutlined /></Button>
            </Dropdown>
        )
    }

    let columns: any = [
        { title: "Image", width: "120px", key: "image", render: ImageRender },
        { title: "Title", key: "title", render: TitleRender },
        { title: "Provider", key: "engineer", render: EngineerRender },
        { title: "Status", width: "120px", dataIndex: "ssos_status", key: "ssos_status", render: SSOSStatusTagRender },
        { title: 'Action', width: "100px", key: 'action', render: ActionColumn }
    ]


    const setters = {
        send: {
            setIsModalOpen: setSendProductModal,
            setProduct
        },
        stop: {
            setIsModalOpen: setStopProductModal,
            setProduct
        }
    }

    const handleStatusOk = () => {
        setSendProductModal(false)
        orderRefetch()
        setProduct(null)
    }

    const handleStopOk = () => {
        setStopProductModal(false)
        orderRefetch()
        setProduct(null)
    }

    const breadcrumbPaths = [
        { path: '/orders', title: 'Orders' },
        { path: '/', title: `Order ${order.name}` },
    ];


    return (
        <>
            <Breadcrumbs items={breadcrumbPaths} />
            <h1>Order {order.name}</h1>
            <p><span className='bold'>Confirmation Number: </span> {order.confirmation_number}</p>
            <p><span className='bold'>Status: </span> <SSOSStatusTag ssos_status={order.ssos_status} /></p>

            <Table columns={columns} dataSource={order.products}></Table>
            <ChangeStatusModal onOk={handleStatusOk} setters={setters.send} orderId={order.id} product={product} isModalOpen={sendProductModal} />
            <StopProductModal onOk={handleStopOk} orderId={order.id} product={product} isModalOpen={stopProductModal} setters={setters.stop} />
        </>
    )
}
export default Details;