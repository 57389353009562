import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import Layout from "../pages/Layout";
import Orders from "../pages/Orders";
import Login from "../pages/Auth";
import Home from "../pages/Home";
//import Signup from "../pages/Auth/signup";
import Details from "../pages/Orders/Details";
import Engineers from "../pages/Engineers";
import Products from "../pages/Products"
import ViewProduct from "../pages/Products/ViewProduct";

import { ClientOrder } from "../pages/ClientOrder";

const Router = () => {
    const browserRouter = createBrowserRouter([
        {
            path: "/",
            element: <Layout />,
            children: [
                {
                    path: 'home',
                    element: <Home />
                },
                {
                    path: "orders",
                    element: <Orders />,
                },
                {
                    path: "orders/:id",
                    element: <Details />,
                },
                {
                    path: "engineers",
                    element: <Engineers />,
                },
                {
                    path: "products",
                    element: <Products />,
                },
                {
                    path: "products/:id",
                    element: <ViewProduct />,
                },
            ],
        },
        {
            path: "/login",
            element: <Login />
        },
        {
            path: '/client/order/:name',
            element: <ClientOrder></ClientOrder>
        }
    ])

    return (
        <RouterProvider router={browserRouter} />
    )
};

export default Router;