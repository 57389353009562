import { Modal, Table, Select, Flex, Divider } from "antd"
import { TableRowSelection } from "antd/es/table/interface"
import { CloudSyncOutlined } from '@ant-design/icons'
import { useQuery, useMutation } from "@apollo/client"
import { useEffect, useState, useContext } from "react"
import { useSelector } from 'react-redux';

import { GET_SHOPIFY_PRODUCTS, CREATE_PRODUCTS } from '../../data/products'
import { GET_ENGINEERS_FOR_SELECT } from '../../data/engineers'
import { Product, Variant } from '../../types/Product'
import { openNotification } from '../../util/notification'
import Loading from "../../components/Loading"

import { SearchInput, handleSearchInputFilter } from "../../components/SearchInput";
import { GraphQLContext } from '../../components/GraphqlProvider/GraphqlProvider'

type ProductsModalProps = {
    isOpen: boolean,
    title: string,
    actions: {
        setIsOpen: any,
        refetchProducts: any
    }
}

type ShopifyProductsModalProps = {
    products: any,
    onSelectChange: any,
    selectedRowKeys: any,
    onFiltered: (search: string) => void
}

const ProductImage = (imageUrl: string) => {
    return (
        <>
            <img src={imageUrl} width={50} alt="" />
        </>
    )
}

const ShopifyProductsTable = ({ products, onSelectChange, selectedRowKeys, onFiltered }: ShopifyProductsModalProps) => {
    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_NONE,
        ],
    }

    const productColumns = [
        { title: "Image", width: 100, dataIndex: "image_url", key: "image_url", render: ProductImage },
        { title: "Title", width: 200, dataIndex: "title", key: "title" },
        { title: "Description", dataIndex: "descripton", key: "description" },
    ]

    return (
        <>
            <SearchInput attribute="title" onFiltered={onFiltered} />
            <Divider />
            <Table rowSelection={rowSelection} size="small" scroll={{ x: true }} columns={productColumns} dataSource={products}></Table>
        </>
    )
}

const ProductsModal = ({ isOpen, actions, ...rest }: ProductsModalProps) => {
    const { setIsOpen, refetchProducts } = actions;
    const [products, setProducts]: any = useState([])
    const [engineers, setEngineers]: any = useState([])
    const [engineer, setEngineer]: any = useState()

    const { graphql } = useContext(GraphQLContext) as any;

    const user = useSelector((state: any) => state.user.data)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [productsData, setProductsData] = useState<any>()
    const [shopifyProductsLoading, setShopifyProductsLoading] = useState(false)
    const [engineersLoading, setEngineersLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    useEffect(() => {
        if (isOpen) {
            fetchProducts()
            fetchEngineers()
        }
    }, [isOpen])

    const fetchProducts = () => {
        setShopifyProductsLoading(true)
        graphql.query({
            query: GET_SHOPIFY_PRODUCTS,
            operationName: 'getShopifyProducts',
            fetchPolicy: 'no-cache'
        }).then((data: any) => {
            setShopifyProductsLoading(false)
            setProducts(data.products)
            setProductsData(data)
        })
    }

    const fetchEngineers = () => {
        setEngineersLoading(true)
        graphql.query({
            query: GET_ENGINEERS_FOR_SELECT,
            operationName: 'getUsers'
        }).then((data: any) => {
            setEngineers(data)
            setEngineersLoading(false)
        })
    }

    const createProducts = async (productsToCreate: any) => {
        setLoading(true)
        graphql.mutate({
            mutation: CREATE_PRODUCTS,
            variables: {
                input: productsToCreate
            }
        }).then(() => {
            refetchProducts()
            setLoading(false)
        })
    }

    useEffect(() => {
        if (engineers.length) {
            setEngineer(engineers[0].value)
        }
    }, [engineers])

    const handleCancelClick = () => {
        setIsOpen(false)
    }

    const handleSubmitClick = async () => {
        try {
            const productsToCreate = [];
            for (const product of products) {
                if (selectedRowKeys.includes(product.shopify_id)) {
                    const { shopify_id, title, description, variants, image_url } = product as Product;
                    const prepareProduct = {
                        shopify_id,
                        title,
                        description,
                        user_id: user.id,
                        engineer: engineer,
                        variants: variants.map(({ shopify_id, title, sku, image_url }: Variant) => ({ shopify_id, title, sku, image_url })),
                        image_url
                    };
                    productsToCreate.push(prepareProduct);
                }
            }
            await createProducts(productsToCreate)
        } catch (error: any) {
            openNotification("error", "Error!", error.message)
        }
        fetchProducts()
        setIsOpen(false)
    }

    const handleSelectChange = (e: any) => {
        setEngineer(e)
    }

    const handleOnFiltered = (search: string) => {
        handleSearchInputFilter(search, 'title', productsData?.products, setProducts)
    }

    if (loading || shopifyProductsLoading || engineersLoading) return <Loading />
    return (
        <>
            <Modal okButtonProps={{ disabled: !selectedRowKeys.length }} onOk={handleSubmitClick} onCancel={handleCancelClick} width={1000} {...rest} open={isOpen}>
                <Flex style={{ marginBottom: 15 }} justify="end" align="center">
                    <label style={{ marginRight: 10, fontWeight: 'bold' }}>Product's engineer: </label>
                    <Select onChange={handleSelectChange} style={{ width: '200px' }} options={engineers} value={engineer}></Select>
                </Flex>
                {productsData?.check && !products.length &&
                    <div style={{ padding: '60px 0' }}>
                        <center>
                            <CloudSyncOutlined style={{ fontSize: 102 }} />
                            <p style={{ fontSize: 20, fontWeight: 600 }}> No more products to add to SSOS</p>
                        </center>
                    </div>
                }
                {isOpen && products.length ? <ShopifyProductsTable selectedRowKeys={selectedRowKeys} onSelectChange={onSelectChange} products={products} onFiltered={handleOnFiltered}></ShopifyProductsTable> : ''}
            </Modal>
        </>
    )
}


export default ProductsModal